import React, { useState, useEffect } from 'react';
import './App.css'; // Import stylów CSS


export default function App() {
  const [life, setLife] = useState(0); // Inicjalizuj życiem
  const [strength, setStrength] = useState('');
  const [stress, setStress] = useState(0);
  const [selectedStat, setSelectedStat] = useState(null);
  const [selectedSubstat, setSelectedSubstat] = useState(null);
  const [empathy, setEmpathy] = useState('');
  const [dexterity, setDexterity] = useState('');
  const [cleverness, setCleverness] = useState('');

  const [strengthSubstats, setStrengthSubstats] = useState({
    heavyGear: '',
    handToHand: '',
    conditioning: '',
  });
  const [dexteritySubstats, setDexteritySubstats] = useState({
    agility: '',
    reflexes: '',
    stealth: '',
  });
  const [empathySubstats, setEmpathySubstats] = useState({
    understanding: '',
    negotiation: '',
    intuition: '',
  });
  const [clevernessSubstats, setClevernessSubstats] = useState({
    problemSolving: '',
    strategy: '',
    observation: '',
  });
  const [rollResults, setRollResults] = useState([]);

  useEffect(() => {
    setLife(strength);
  }, [strength]);

  const handleStatPress = (stat) => {
    if (selectedStat === stat) {
      setSelectedStat(null); // Jeśli kliknięta statystyka jest już wybrana, odznacz ją
    } else {
      setSelectedStat(stat); // Jeśli nie, wybierz ją
    }
    setSelectedSubstat(null); // resetuj substatystyki przy każdej zmianie statystyki
  };

  const handleCriticalWound = () => {
    // Rzuć 2 kośćmi k6
    const diceRolls = [Math.floor(Math.random() * 6) + 1, Math.floor(Math.random() * 6) + 1];
    
    // Przygotuj tekst do wyświetlenia
    const resultText = `Wyniki rzutu 2 kośćmi k6: ${diceRolls.join(' ')}`;
    
    // Wyświetl wynik w alercie
    alert(resultText);
  };
  const handlePanicTest = () => {
    // Rzucenie kostką K6
    const diceRoll = Math.floor(Math.random() * 6) + 1;
    // Dodanie wyniku stresu
    const total = diceRoll + stress;
  
    // Komunikat w zależności od wyniku
    let message = '';
    if (total <= 6) {
      message = 'JAKOŚ SIĘ TRZYMASZ. Udaje ci się wziąć się w garść. Z trudem.';
    } else if (total === 7) {
      message = 'TIK NERWOWY. Twój POZIOM STRESU I POZIOM STRESU wszystkich przyjaznych PG W BLISKIM zasięgu od ciebie rośnie o jeden.';
    }else if (total === 8) {
      message = 'DRGAWKI. Nie możesz opanować drżenia. Wszystkie testy umiejętności oparte na ZRĘCZNOści otrzymują modyfikator -2, dopóki nie przejdzie ci atak paniki.';
    }else if (total === 9) {
      message = 'UPUSZCZENIE PRZEDMIOTŮ. Czy to z powodu stresu, dezorientacji czy świadomości, że i tak wszyscy umrzecie, upuszczasz broń lub inny ważny przedmiot - MG decyduje, który. Twój POZIOM STRESU rośnie o jeden.';
    }else if (total === 10) {
      message = 'ZAMARCIE W BEZRUCHU. Zamierasz ze strachu lub napięcia na jedną rundę, tracąc następną akcję wolną. Twój POZIOM STRESU I POZIOM STRESU wszystkich przyjaznych PG W BLISKIM Zasięgu od ciebie rośnie o jeden.';
    }else if (total === 11) {
      message = 'POSZUKIWANIE KRYJÓWKI. Musisz użyć swojej następnej akcji, aby oddalić się od niebezpieczeństwa i, o ile to możliwe, znaleźć bezpieczne miejsce. Możesz wykonać test wycofania się (patrz strona 93), jeśli znajdujesz się w ZWARCIU Z wrogiem. Twój POZIOM STRESU maleje o jeden, ale POZIOM STRESU wszystkich przyjaznych PG W BLISKIM Zasięgu od ciebie rośnie o jeden. Po upływie jednej rundy możesz zacząć zachowywać się normalnie.';
    }else if (total === 12) {
      message = 'KRZYK. Wrzeszczysz na całe gardło przez jedną rundę, tracąc następną akcję wolną. Twój POZIOM STRESU spada o jeden, ale każda przyjazna postać, która słyszy twój krzyk, musi natychmiast wykonać Test Paniki.';
    }else if (total === 13) {
      message = 'UCIECZKA. Dłużej już nie wytrzymasz. Musisz uciec w bezpieczne miejsce i nikt nie przeko- na cię, by je opuścić. Nikogo nie zaatakujesz i nie podejmiesz żadnego niebezpiecznego działania. W chwili ucieczki nie możesz wykonać testu wycofania się (patrz strona 93), jeśli znajdujesz się w ZWARCIU Z wrogiem. Twój POZIOM STRESU spada o jeden, ale każda przyjazna postać, która widzi twoją ucieczkę, musi natychmiast wykonać Test Paniki.';
    }else if (total === 14) {
      message = 'MORDERCZY SZAŁ. Natychmiast atakujesz najbliższą osobę lub inne stworzenie - wroga bądź przyjaciela. Nie przestajesz, dopóki ty lub twój cel nie zostaniecie pokonani. Każda przyjazna postać, która widzi twój napad szału, musi natychmiast wykonać Test Paniki.';
    } else {
      message = 'KATATONIA. Padasz na podłogę wpatrzony tępo w przestrzeń, niezdolny mówić ani się poruszać.';
    }
  
    alert(`Wynik rzutu k6: ${diceRoll}\nStres: ${stress}\n${message}`);
  };

  // Funkcja wyboru substatystyki
  const handleSubstatPress = (substat) => {
    setSelectedSubstat(prev => (prev === substat ? null : substat)); // Toggle dla substatystyk
  };

  // Obsługa zmian w substatystykach
  const handleSubstatChange = (stat, substat, value) => {
    if (stat === 'strength') {
      setStrengthSubstats(prev => ({
        ...prev,
        [substat]: value,
      }));
    } else if (stat === 'dexterity') {
      setDexteritySubstats(prev => ({
        ...prev,
        [substat]: value,
      }));
    } else if (stat === 'empathy') {
      setEmpathySubstats(prev => ({
        ...prev,
        [substat]: value,
      }));
    } else if (stat === 'cleverness') {
      setClevernessSubstats(prev => ({
        ...prev,
        [substat]: value,
      }));
    }
  };

  const rollDice = () => {
    if (!selectedStat) {
      alert('Wybierz statystykę przed rzutem.');
      return;
    }
  
    const baseValue = parseInt(
      selectedStat === 'strength' ? strength :
      selectedStat === 'dexterity' ? dexterity :
      selectedStat === 'empathy' ? empathy :
      selectedStat === 'cleverness' ? cleverness : '0'
    ) || 0;
  
    const substatValue = parseInt(
      selectedStat === 'strength' ? strengthSubstats[selectedSubstat] || '0' :
      selectedStat === 'dexterity' ? dexteritySubstats[selectedSubstat] || '0' :
      selectedStat === 'empathy' ? empathySubstats[selectedSubstat] || '0' :
      selectedStat === 'cleverness' ? clevernessSubstats[selectedSubstat] || '0' :
      '0'
    );
  
    const numDice = baseValue + substatValue;
    const stressDice = stress;
  
    if (numDice <= 0 && stressDice <= 0) {
      alert('Brak kości do rzutu.');
      return;
    }
  
    // Roll dice
    const skillResults = Array.from({ length: numDice }, () => Math.floor(Math.random() * 6) + 1);
    const stressResults = Array.from({ length: stressDice }, () => Math.floor(Math.random() * 6) + 1);
  
    // Separate skill and stress results
    setRollResults({
      skill: skillResults,
      stress: stressResults
    });
  };

  return (
    <div className="container">
      <h1>Statystyki Postaci:</h1>
      <div className="life-container">
      
      <span className="life-value">Życie: {life}</span>
      <button className='life-button' onClick={() => setLife(life - 1)}>-</button>
      <button className='life-button' onClick={() => setLife(life + 1)}>+</button>
 
    </div>
    {life === 0 && (
  <div className="critical-wound-container">
    <button className="critical-wound-button" onClick={handleCriticalWound}>
      Rana Krytyczna
    </button>
  </div>
)}
      <h1>Umiejętności:</h1>
  
      {/* Kontener Siła */}
      <div className="stat-container">
        <button
          className={`button ${selectedStat === 'strength' ? 'button-active' : ''}`}
          onClick={() => handleStatPress('strength')}
        >
          Siła
        </button>
        <input
          className="input"
          type="number"
          placeholder="Wartość"
          value={strength}
          onChange={(e) => setStrength(e.target.value)}
        />
      </div>
  
      {/* Kontener substatystyk dla Siły */}
      {selectedStat === 'strength' && (
        <div className="substat-container">
  {[
    { label: 'Ciężki Sprzęt', key: 'ciezkiSprzet' },
    { label: 'Walka Wręcz', key: 'walkaWrecz' },
    { label: 'Kondycja', key: 'kondycja' }
  ].map(substat => (
    <div
      key={substat.key}
      className={`substat-box ${selectedSubstat === substat.key ? 'substat-active' : ''}`}
      onClick={() => handleSubstatPress(substat.key)}
    >
      <span>{substat.label}</span>
      <input
        className="substat-input"
        type="number"
        placeholder="Wartość"
        value={strengthSubstats[substat.key]}
        onChange={(e) => handleSubstatChange('strength', substat.key, e.target.value)}
      />
    </div>
  ))}
</div>
      )}
  
      {/* Kontener Zręczność */}
      <div className="stat-container">
  <button
    className={`button ${selectedStat === 'dexterity' ? 'button-active' : ''}`}
    onClick={() => handleStatPress('dexterity')}
  >
    Zręczność
  </button>
  <input
    className="input"
    type="number"
    placeholder="Wartość"
    value={dexterity}
    onChange={(e) => setDexterity(e.target.value)}
  />
</div>

  
      {selectedStat === 'dexterity' && (
        <div className="substat-container">
          {['Walka Dystansowa', 'Sprawność', 'Pilotowanie'].map(substat => (
            <div
              key={substat}
              className={`substat-box ${selectedSubstat === substat ? 'substat-active' : ''}`}
              onClick={() => handleSubstatPress(substat)}
            >
              <span>{substat.charAt(0).toUpperCase() + substat.slice(1).replace(/([A-Z])/g, ' $1')}</span>
              <input
                className="substat-input"
                type="number"
                placeholder="Wartość"
                value={dexteritySubstats[substat]}
                onChange={(e) => handleSubstatChange('dexterity', substat, e.target.value)}
              />
            </div>
          ))}
        </div>
      )}
  
      {/* Kontener Empatia */}
      <div className="stat-container">
        <button
          className={`button ${selectedStat === 'empathy' ? 'button-active' : ''}`}
          onClick={() => handleStatPress('empathy')}
        >
          Empatia
        </button>
        <input
          className="input"
          type="number"
          placeholder="Wartość"
          value={empathy}
          onChange={(e) => setEmpathy(e.target.value)}
        />
      </div>
  
      {selectedStat === 'empathy' && (
        <div className="substat-container">
          {['Ddowodzenie', 'Manipulacja', 'Pomoc Medyczna'].map(substat => (
            <div
              key={substat}
              className={`substat-box ${selectedSubstat === substat ? 'substat-active' : ''}`}
              onClick={() => handleSubstatPress(substat)}
            >
              <span>{substat.charAt(0).toUpperCase() + substat.slice(1).replace(/([A-Z])/g, ' $1')}</span>
              <input
                className="substat-input"
                type="number"
                placeholder="Wartość"
                value={empathySubstats[substat]}
                onChange={(e) => handleSubstatChange('empathy', substat, e.target.value)}
              />
            </div>
          ))}
        </div>
      )}
  
      {/* Kontener Spryt */}
      <div className="stat-container">
        <button
          className={`button ${selectedStat === 'cleverness' ? 'button-active' : ''}`}
          onClick={() => handleStatPress('cleverness')}
        >
          Spryt
        </button>
        <input
          className="input"
          type="number"
          placeholder="Wartość"
          value={cleverness}
          onChange={(e) => setCleverness(e.target.value)}
        />
      </div>
  
      {selectedStat === 'cleverness' && (
        <div className="substat-container">
          {['Spostrzegawczość', 'Przetrwanie', 'Komputery'].map(substat => (
            <div
              key={substat}
              className={`substat-box ${selectedSubstat === substat ? 'substat-active' : ''}`}
              onClick={() => handleSubstatPress(substat)}
            >
              <span>{substat.charAt(0).toUpperCase() + substat.slice(1).replace(/([A-Z])/g, ' $1')}</span>
              <input
                className="substat-input"
                type="number"
                placeholder="Wartość"
                value={clevernessSubstats[substat]}
                onChange={(e) => handleSubstatChange('cleverness', substat, e.target.value)}
              />
            </div>
          ))}
        </div>
      )}
  
  
<button className="roll-button" onClick={rollDice}>Rzuć kośćmi</button>
      <div className='szlify'>
      
  
      {/* Wyświetlanie wyników rzutu */}
      {rollResults.skill && rollResults.skill.length > 0 && (
        <div className="results-container">
          <h3>Wyniki rzutów:</h3>
          <div className="results">
            {rollResults.skill.map((result, index) => (
              <span
                key={index}
                style={{
                  color: 'white',
                  marginRight: 5,
                }}
              >
                {result}
              </span>
            ))}
          </div>
        </div>
      )}
  
      {rollResults.stress && rollResults.stress.length > 0 && (
        <div className="results-container">
          <div className="results">
            {rollResults.stress.map((result, index) => (
              <span
                key={index}
                style={{
                  color: 'yellow',
                  marginRight: 5,
                }}
              >
                {result}
              </span>
            ))}
          </div>
        </div>
      )}
      </div>
      <div className="stress-container">
  <span className='stresnapis'>Stres: {stress}</span>
  <button onClick={() => setStress(stress - 1)}>-</button>
  <button onClick={() => setStress(stress + 1)}>+</button>
</div>
<div className="test-panic-container">
  <button className="test-panic-button" onClick={handlePanicTest}>
    Test paniki
  </button>
</div>


      <div className="footer">
  <p>© 2024 Alien RPG App.</p>
</div>
    </div>
  


);

}
